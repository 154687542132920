.about-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    font: normal lighter 15px/20px "Red Hat Display", sans-serif;
    color: white;
    align-items: center;
    padding-top: 2rem;
    overflow: hidden;
}

.image-container {
    border-top: 1px solid rgba(107,239,242,0.7);
    grid-column: 2/span 10;
    display: flex;
    justify-content: center;
}

.image-container img {
    width: 100%;
    max-width: 508px;
    margin: 3rem;
}

.about-content {
    grid-column: 2/span 10;
    text-align: center;
    height: inherit;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 0 1rem 3rem 1rem;
}

.about-content p {
    margin: 0;
}