header {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    font: normal lighter 15px/20px "Red Hat Display", sans-serif;
}

.left-header {
    grid-column: 2/span 5;
    display: flex;
    align-items: center;
}

.right-header {
    grid-column: 7/span 5;
    text-align: end;
}

.left-header img {
    width: 100%;
    max-width: 450px;
}

header a {
    text-decoration: none;
    color: white;
}

header ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.right-header ul {
    padding-top: 2rem;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.left-header ul {
    gap: 1rem;
}

.underline {
    display: inline;
    position: relative;
    overflow: hidden;
}

.underline:after {
    content: "";
    position: absolute;
    z-index: 3;
    right: 0;
    width: 0;
    bottom: -2px;
    background: #5CE6E6;
    height: 1px;
    transition-property: width;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.underline:hover:after, .underline:focus:after, .underline:active:after {
    left: 0;
    right: auto;
    width: 100%;
}

@media screen and (max-width: 600px) {
    header {
        background-color: black;
    }
    .right-header ul {
        padding-top: 1rem;
    }
}
