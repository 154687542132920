.main-body-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.middle {
    grid-column: 2/span 5;
    border-right: 1px solid rgba(107,239,242,0.7);
    padding: 3rem 3rem 0 3rem;
}

.middle-end {
    grid-column: 7/span 5;
    padding: 3rem 3rem 0 3rem;
}

.middle:first-child, .middle-end:nth-child(2) {
    border-top: 1px solid rgba(107,239,242,0.7);
    padding-bottom: 3rem;
}

.middle:nth-child(3), .middle-end:nth-child(4){
    padding-top: 0;
    padding-bottom: 3rem;
}

.middle:nth-last-child(2), .middle-end:last-child {
    padding-bottom: 3rem;
}

.shorts-container {
    display: grid;
    grid-column: 2/ span 10;
    grid-template-columns: repeat(9, 1fr);
    align-items: center;
    padding: 0 3rem;
    grid-column-gap: 3rem;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* 9 / 16 = 0.5625 */
}

.first-player-wrapper-mobile {
    grid-column: 1/ span 3;
    position: relative;
    padding-top: 125%; /* 1.350 / 1080 = 1.25 */
}

.second-player-wrapper-mobile {
    grid-column: 4/ span 3;
    position: relative;
    padding-top: 177.77%; /* 16 / 9 = 1.7777 */
}

.third-player-wrapper-mobile {
    grid-column: 7/ span 3;
    position: relative;
    padding-top: 100%; /* square */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (max-width: 600px) {
    .main-body-container {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        margin: 2rem;
        overflow: hidden;
    }
    .middle, .middle-end, .middle:first-child, .middle-end:nth-child(2), .middle:nth-child(3), .middle-end:nth-child(4), .middle:nth-last-child(2), .middle-end:last-child {
        border: none;
        padding: 0;
    }
    .shorts-container {
        display: block;
        padding: 0;
    }
    .second-player-wrapper-mobile {
        margin: 3rem 0;
    }
}
