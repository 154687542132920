footer {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.social-media-icons {
    border-top: 1px solid rgba(107,239,242,0.7);
    grid-column: 2 / span 10;
}

.social-media-icons ul {
    display: flex;
    justify-content: center;
    gap: 3rem;
    margin: 2rem;
    align-items: center;
}

@media screen and (max-width: 600px) {
    footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: black;
        transition-property: bottom;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
    }
    .footer-position-correction {
        height: 98px;
    }
    .social-media-icons ul {
        gap: 2rem;
    }
}