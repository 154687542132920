*, ::after, ::before { box-sizing: border-box; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scene-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -1;
  opacity: 1;
  -webkit-transition: .6s ease-in-out;
  transition: .6s ease-in-out;
}

@media (min-width: 1024px) {
  .scene-container {
      margin: 0;
  }
}

.scene {
  position: relative;
  overflow: hidden
}

.scene,.layer {
  display: block;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 0
}

.layer:nth-of-type(1) .icon {
  -webkit-transition-delay: .1s;
  transition-delay: .1s
}

.layer:nth-of-type(2) .icon {
  -webkit-transition-delay: .2s;
  transition-delay: .2s
}

.layer:nth-of-type(3) .icon {
  -webkit-transition-delay: .3s;
  transition-delay: .3s
}

.layer:nth-of-type(4) .icon {
  -webkit-transition-delay: .4s;
  transition-delay: .4s
}

.layer:nth-of-type(5) .icon {
  -webkit-transition-delay: .5s;
  transition-delay: .5s
}

.layer:nth-of-type(6) .icon {
  -webkit-transition-delay: .6s;
  transition-delay: .6s
}

.layer:nth-of-type(7) .icon {
  -webkit-transition-delay: .7s;
  transition-delay: .7s
}

.layer:nth-of-type(8) .icon {
  -webkit-transition-delay: .8s;
  transition-delay: .8s
}

.layer:nth-of-type(9) .icon {
  -webkit-transition-delay: .9s;
  transition-delay: .9s
}

.layer:nth-of-type(10) .icon {
  -webkit-transition-delay: 1s;
  transition-delay: 1s
}

.icon {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
  position: absolute;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  width: 20px;
  height: 20px;
  -webkit-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  outline: 1px solid transparent
}

.icon:nth-of-type(6) {
  -webkit-transition-delay: .6s;
  transition-delay: .6s
}

.icon img {
  width: 100%
}

@media (min-width: 1024px) {
  .loading-scene .icon {
      -webkit-transform:translate3d(0, 0, 0) scale(0);
      transform: translate3d(0, 0, 0) scale(0);
      left: 50%;
      top: 50%
  }
}

.bg-icon-1 {
  background-color: rgba(107,239,242,0.5);
  left: 12%;
  top: 75%
}

@media (min-width: 768px) {
  .bg-icon-1 {
      background-color:#6beff2;
      left: 25%;
      top: 80%
  }
}

.bg-icon-2 {
  border: 1px solid rgba(107,239,242,0.7);
  left: 25%;
  top: 15%
}

@media (min-width: 768px) {
  .bg-icon-2 {
      left:40%;
      top: 20%
  }
}

.bg-icon-3 {
  border: 1px solid #8774ff;
  left: 75%;
  top: 15%
}

@media (min-width: 768px) {
  .bg-icon-3 {
      left:17%;
      top: 28%
  }
}

.bg-icon-4 {
  border: 1px solid rgba(107,239,242,0.7);
  left: 80%;
  top: 50%;
  width: 30px;
  height: 30px
}

@media (min-width: 768px) {
  .bg-icon-4 {
      display:block;
      left: 5%;
      top: 80%;
      width: 50px;
      height: 50px
  }
}

.bg-icon-5 {
  border: 1px solid #8774ff;
  left: 60%;
  top: 60%;
  width: 30px;
  height: 30px;
  display: none
}

@media (min-width: 768px) {
  .bg-icon-5 {
      display:block
  }
}

.bg-icon-6 {
  background-color: #8774ff;
  left: 70%;
  top: 90%;
  display: none
}

@media (min-width: 768px) {
  .bg-icon-6 {
      display:block
  }
}

.bg-icon-7 {
  border: 1px solid rgba(255,255,255,0.5);
  left: 70%;
  top: 10%;
  width: 100px;
  height: 100px;
  display: none
}

@media (min-width: 768px) {
  .bg-icon-7 {
      display:block
  }
}

.bg-icon-8 {
  background-color: rgba(135,116,255,0.5);
  left: 5%;
  top: 35%;
  width: 20px;
  height: 20px
}

@media (min-width: 768px) {
  .bg-icon-8 {
      background-color:#8774ff;
      left: 5%;
      top: 20%;
  }
}

.bg-icon-9 {
  background-color: rgba(107,239,242,0.5);
  left: 85%;
  top: 30%;
  width: 20px;
  height: 20px
}

@media (min-width: 768px) {
  .bg-icon-9 {
      background-color:#6beff2;
      left: 85%;
      top: 70%
  }
}

.bg-icon-10 {
  background-color: rgba(135,116,255,0.5);
  left: 75%;
  top: 80%;
  width: 20px;
  height: 20px
}

@media (min-width: 768px) {
  .bg-icon-10 {
      background-color: #fff;
      left: 85%;
      top: 40%
  }
}

.scene-in .icon {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
  opacity: 1;
  -webkit-animation-name: rotate-icon;
  animation-name: rotate-icon;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear
}

.scene-in .icon-rotate {
  -webkit-transform: translate3d(0, 0, 0) scale(1) rotate(45deg);
  transform: translate3d(0, 0, 0) scale(1) rotate(45deg);
  -webkit-animation-name: rotate-icon-45;
  animation-name: rotate-icon-45
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .scene-in .icon {
      -webkit-animation: none;
      animation: none
  }
}

.light .scene-in .bg-icon-2 {
  border: 1px solid #4deff2
}

.light .scene-in .bg-icon-3 {
  background-color: rgba(0,0,0,0.5)
}

.light .scene-in .bg-icon-5 {
  background-color: rgba(0,0,0,0.5)
}

.light .scene-in .bg-icon-7 {
  border: 1px solid rgba(0,0,0,0.5)
}

.single-item .scene-container .icon {
  opacity: 0 !important
}

@-webkit-keyframes rotate-icon {
  from {
      -webkit-transform: translateZ(1px) rotate(0deg);
      transform: translateZ(1px) rotate(0deg)
  }

  to {
      -webkit-transform: translateZ(1px) rotate(360deg);
      transform: translateZ(1px) rotate(360deg)
  }
}

@keyframes rotate-icon {
  from {
      -webkit-transform: translateZ(1px) rotate(0deg);
      transform: translateZ(1px) rotate(0deg)
  }

  to {
      -webkit-transform: translateZ(1px) rotate(360deg);
      transform: translateZ(1px) rotate(360deg)
  }
}

@-webkit-keyframes rotate-icon-45 {
  from {
      -webkit-transform: translateZ(1px) rotate(45deg);
      transform: translateZ(1px) rotate(45deg)
  }

  to {
      -webkit-transform: translateZ(1px) rotate(405deg);
      transform: translateZ(1px) rotate(405deg)
  }
}

@keyframes rotate-icon-45 {
  from {
      -webkit-transform: translateZ(1px) rotate(45deg);
      transform: translateZ(1px) rotate(45deg)
  }

  to {
      -webkit-transform: translateZ(1px) rotate(405deg);
      transform: translateZ(1px) rotate(405deg)
  }
}