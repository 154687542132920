.main-header-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.video-ticker-container {
  grid-column: 3/span 8;
  margin: 3rem;
}

.ticker-wrap {
  width: 100%;
  height: 1.5rem;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: 1px solid rgba(107,239,242,0.7);
  border-top: 1px solid rgba(107,239,242,0.7);
  background-color: black;
}
.ticker {
  display: inline-block;
  animation: marquee 100s linear infinite;
}
.item-collection-1 {
  position: relative;
  left: 0%;
  animation: swap 100s linear infinite;
}

.item {
  display: inline-block;
  padding: 0 1rem;
  font-size: 1rem;
  color: #5CE6E6;
  font-family: "Red Hat Display", sans-serif;
  font-style: italic;
}

/* Transition */
@keyframes marquee {
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(-100%)
  }
}

@keyframes swap {
  0%, 50% {
    left: 0%;
  }
  50.01%,
  100% {
    left: 100%;
  }
}

@media screen and (max-width: 600px) {
  .main-header-container {
    display: flex;
    margin: 2rem;
  }
  .video-ticker-container {
    width: 100%;
    margin: 0;
  }
}
